// src/index.js
import { fabric } from 'fabric';
import tunaImg from "../img/tuna.png";
import canImg from "../img/can.png";
import wifImg from "../img/wif.png";
import duchiImg from "../img/duchi.png";


document.addEventListener('DOMContentLoaded', () => {
    console.log('JavaScript is loaded and running!');

    const hatButtons = document.querySelectorAll('.hats');
    const canvasElement = document.getElementById('imageCanvas');
    if (canvasElement) {
        canvasElement.style.webkitUserSelect = 'none';
        canvasElement.style.khtmlUserSelect = 'none';
        canvasElement.style.mozUserSelect = 'none';
        canvasElement.style.msUserSelect = 'none';
        canvasElement.style.userSelect = 'none';
    }
    canvasElement.addEventListener('touchstart', (e) => e.preventDefault());
    canvasElement.addEventListener('mousedown', (e) => e.preventDefault());

    hatButtons.forEach(button => {
        button.addEventListener('click', () => {
            // Remove 'selected' class from all hat buttons
            hatButtons.forEach(btn => btn.classList.remove('selected'));

            // Add 'selected' class to the clicked hat button
            button.classList.add('selected');

            // Handle adding the hat to the canvas based on the selected button
            // Add your logic here to add the selected hat to the canvas
        });
    });

    let btnUpload = $("#upload_file"),
        btnOuter = $(".button_outer");
    btnUpload.on("change", function(e) {
        let ext = btnUpload.val().split('.').pop().toLowerCase();
        if ($.inArray(ext, ['gif', 'png', 'jpg', 'jpeg']) == -1) {
            $(".error_msg").text("Not an Image...");
        } else {
            $(".error_msg").text("");
            btnOuter.addClass("file_uploading");
            setTimeout(function() {
                btnOuter.addClass("file_uploaded");
            }, 3000);
            let uploadedFile = URL.createObjectURL(e.target.files[0]);
            setTimeout(function() {
                $("#uploaded_view").append('<img src="' + uploadedFile + '" />').addClass("show");
            }, 3500);
        }
    });
    $(".file_remove").on("click", function(e) {
        $("#uploaded_view").removeClass("show");
        $("#uploaded_view").find("img").remove();
        btnOuter.removeClass("file_uploading");
        btnOuter.removeClass("file_uploaded");
    });

    let canvas = new fabric.Canvas('imageCanvas');
    let currentHat = null; // Track the current hat

    function adjustCanvasSize() {
        const canvasWidth = window.innerWidth * 0.8; // 80% of window width
        const canvasHeight = canvasWidth * (uploadedImage.height / uploadedImage.width); // Maintain aspect ratio

        canvas.setDimensions({ width: canvasWidth, height: canvasHeight });
    }

// Handle resizing the canvas when the window is resized
    window.addEventListener('resize', adjustCanvasSize);

    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
            (window.innerWidth < 768);
    }

    function handleImage(e) {
        const reader = new FileReader();
        reader.onload = function(event) {
            fabric.Image.fromURL(event.target.result, function(img) {
                const maxCanvasSize = isMobileDevice() ? 900 : 500;

                let scale = Math.min(maxCanvasSize / img.width, maxCanvasSize / img.height);
                const scaledWidth = img.width * scale;
                const scaledHeight = img.height * scale;

                canvas.setDimensions({ width: scaledWidth, height: scaledHeight });

                // Adjust the canvas margin from the top to be 50% only on mobile
                if (isMobileDevice()) {
                    canvas.getElement().style.marginTop = '25%';
                } else {
                    canvas.getElement().style.marginTop = '0'; // Reset to default for non-mobile
                }

                img.set({
                    scaleX: scale,
                    scaleY: scale,
                    left: 0,
                    top: 0,
                    selectable: false,
                    evented: false,
                });

                canvas.clear();
                canvas.add(img);
                canvas.sendToBack(img);

                canvas.renderAll();
                // Check if on a mobile device after the image is loaded
                if (isMobileDevice()) {
                    const mainFull = document.querySelector('.main_full');
                    if (mainFull) {
                        mainFull.style.height = '100vh'; // Set only after an image is loaded and on mobile
                    }
                }
            });
        };
        reader.readAsDataURL(e.target.files[0]);
    }


    function addHat(hatPath) {
        if (currentHat) {
            canvas.remove(currentHat);
        }
        fabric.Image.fromURL(hatPath, function(img) {
            // Adjust the size of corner handles and padding for mobile devices
            const controlSizeMultiplier = isMobileDevice() ? 3 : 1; // 2.5x larger on mobile
            img.set({
                left: (canvas.width - img.width * 0.5) / 2,
                top: 10,
                scaleX: 0.5,
                scaleY: 0.5,
                cornerSize: 20 * controlSizeMultiplier, // Adjust corner handles based on device
                padding: 10 * controlSizeMultiplier, // Adjust padding based on device
                hasControls: true,
                hasBorders: true,
                lockRotation: false,
                lockScalingX: false,
                lockScalingY: false,
                lockMovementX: false,
                lockMovementY: false,
                transparentCorners: false,
                borderColor: 'black',
                cornerColor: 'black',
                cornerStyle: 'circle', // Optional: change corner handles to circles
            });
            canvas.add(img).setActiveObject(img);
            currentHat = img; // Keep track of the current hat
            canvas.bringToFront(img); // Ensure the hat is above the background image
        });
    }


    // Event listeners for adding hats
    document.getElementById('upload_file').addEventListener('change', handleImage, false);
    document.getElementById('hat1').addEventListener('click', () => addHat(canImg));
    document.getElementById('hat2').addEventListener('click', () => addHat(wifImg));
    document.getElementById('hat3').addEventListener('click', () => addHat(tunaImg));
    document.getElementById('hat4').addEventListener('click', () => addHat(duchiImg));

    // Function to add watermark to the canvas
    // function addWatermark(canvas, watermarkPath, canvasWidth, canvasHeight) {
    //     fabric.Image.fromURL(watermarkPath, function(watermark) {
    //         // Calculate the size of the watermark
    //         const watermarkRatio = 1 / 10; // 1:15 ratio of the canvas
    //         const watermarkWidth = canvasWidth * watermarkRatio;
    //         const watermarkHeight = canvasHeight * watermarkRatio;
    //
    //         watermark.set({
    //             left: canvasWidth - watermarkWidth - 10, // 10px padding from right
    //             top: canvasHeight - watermarkHeight - 10, // 10px padding from bottom
    //             scaleX: watermarkWidth / watermark.width,
    //             scaleY: watermarkHeight / watermark.height,
    //             selectable: false, // Make watermark non-selectable
    //             evented: false, // Prevent events on the watermark
    //         });
    //
    //         canvas.add(watermark);
    //     });
    // }



    // Function to download canvas content as an image
    document.getElementById('downloadBtn').addEventListener('click', () => {
        const dataURL = canvas.toDataURL({
            format: 'png',
            multiplier: 1,
        });
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'canwiftuna_pfp.png';
        link.click();
    });

});
